.login {
  min-height: 100vh;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login .loginBox {
  margin-top: 5rem;
  width: 543px;
  height: 448px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 6px 13px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  /* padding: 1rem; */
}
.login .loginBox h1 {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #000;
}
.login .loginBox .loginFormContainer {
  width: auto;
  padding: 1.5rem 3rem;
  padding-top: 1rem;
}
.login .loginBox p {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #949494;
}
.login .loginBox .inputfield {
  outline: none;
  border: none;
  background: #e8e8e8;
  border-radius: 10px;
  width: 440px;
  height: 61px;
  margin: 8px 0;
  padding: 12px;
}

.inputfield:focus {
  /* border: 1px solid red; */
  /* outline: blueviolet; */
  border: 1px solid #00aeef !important;
}

.login .loginBox .rememberforget {
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loginFormContainer span {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  padding-top: 1rem;
}
.loginbtn {
  border-radius: 0.5rem;
}

@media (max-width: 710px) {
  .login .loginBox {
    width: auto;
    height: auto;
  }
  .login .loginBox .inputfield {
    width: 100%;
    height: auto;
  }
  .login .loginBox .loginFormContainer {
    padding: 1.5rem 2.5rem;
  }
}
