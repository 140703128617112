
.HEADER {
    margin: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 50px;
    height: fit-content;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: space-evenly;
    
  }
  
  .HEADER_TXT {
    font-size: 14px;
    color: white;
    font-weight: 500;
  }