@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply relative z-1 bg-whiten font-satoshi text-base font-normal text-body ;
  }
}

@layer components {
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .chat-height {
    @apply h-[calc(100vh_-_8.125rem)] lg:h-[calc(100vh_-_5.625rem)];
  }
  .inbox-height {
    @apply h-[calc(100vh_-_8.125rem)] lg:h-[calc(100vh_-_5.625rem)];
  }
}


.spanText .ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  /* margin-bottom: 24px; */
  vertical-align: top;
}

/* third-party libraries CSS */

.tableCheckbox:checked ~ div span {
  @apply opacity-100;
}
.tableCheckbox:checked ~ div {
  @apply border-primary bg-primary;
}

.apexcharts-legend-text {
  @apply !text-body dark:!text-bodydark;
}
/* .apexcharts-text {
  @apply !fill-body dark:!fill-bodydark;
} */
.apexcharts-xcrosshairs {
  @apply !fill-stroke dark:!fill-strokedark;
}
.apexcharts-gridline {
  @apply !stroke-stroke dark:!stroke-strokedark;
}
.apexcharts-series.apexcharts-pie-series path {
  @apply dark:!stroke-transparent;
}
.apexcharts-legend-series {
  @apply !inline-flex gap-1.5;
}
.apexcharts-tooltip.apexcharts-theme-light {
  @apply dark:!border-strokedark dark:!bg-boxdark;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  @apply dark:!border-strokedark dark:!bg-meta-4;
}
.apexcharts-xaxistooltip,
.apexcharts-yaxistooltip {
  @apply dark:!border-meta-4 dark:!bg-meta-4 dark:!text-bodydark1;
}
.apexcharts-xaxistooltip-bottom:after {
  @apply dark:!border-b-meta-4;
}
.apexcharts-xaxistooltip-bottom:before {
  @apply dark:!border-b-meta-4;
}

.flatpickr-day.selected {
  @apply border-primary bg-primary hover:border-primary hover:bg-primary;
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  @apply fill-primary;
}
.flatpickr-calendar.arrowTop:before {
  @apply dark:!border-b-boxdark;
}
.flatpickr-calendar.arrowTop:after {
  @apply dark:!border-b-boxdark;
}
.flatpickr-calendar {
  @apply !p-6 dark:!bg-boxdark dark:!text-bodydark dark:!shadow-8 2xsm:!w-auto;
}
.flatpickr-day {
  @apply dark:!text-bodydark;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  @apply !top-7 dark:!fill-white dark:!text-white;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  @apply !left-7;
}
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  @apply !right-7;
}
span.flatpickr-weekday,
.flatpickr-months .flatpickr-month {
  @apply dark:!fill-white dark:!text-white;
}
.flatpickr-day.inRange {
  @apply dark:!border-meta-4 dark:!bg-meta-4 dark:!shadow-7;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.selected,
.flatpickr-day.endRange {
  @apply dark:!text-white;
}

.map-btn .jvm-zoom-btn {
  @apply flex h-7.5 w-7.5 items-center justify-center rounded border border-stroke bg-white px-0 pt-0 pb-0.5 text-2xl leading-none text-body hover:border-primary hover:bg-primary hover:text-white dark:border-strokedark dark:text-bodydark dark:hover:border-primary dark:hover:text-white;
}
.mapOne .jvm-zoom-btn {
  @apply left-auto top-auto bottom-0;
}
.mapOne .jvm-zoom-btn.jvm-zoomin {
  @apply right-10;
}
.mapOne .jvm-zoom-btn.jvm-zoomout {
  @apply right-0;
}
.mapTwo .jvm-zoom-btn {
  @apply top-auto bottom-0;
}
.mapTwo .jvm-zoom-btn.jvm-zoomin {
  @apply left-0;
}
.mapTwo .jvm-zoom-btn.jvm-zoomout {
  @apply left-10;
}

.taskCheckbox:checked ~ .box span {
  @apply opacity-100;
}
.taskCheckbox:checked ~ p {
  @apply line-through;
}
.taskCheckbox:checked ~ .box {
  @apply border-primary bg-primary dark:border-primary;
}

[x-cloak] {
  display: none !important;
}

@media (max-width: 710px) {
  .profileForm {
    width: 100%;
  }
}

.text-primary {
  color: #3df3ff;
}

.lineHeight {
  line-height: 1.5rem;
}

body {
  line-height: 0rem;
}

/* 
.swal-button--cancel {
  background-color: #d33;
  padding: 20px;
  color:white;
}

.swal-button--confirm {
  background-color: #3085d6;
  padding: 20px;
  color:white;
} */

/* .swal-button--confirm:hover {
  background-color: #3df3ff;
  padding: 20px;
  color:black;
}

.swal-button--cancel:hover {
  background-color: black;
  padding: 20px;
  color:white;
} */
