/* Example CSS */
.material-react-table {
    width: 100%; /* Set the overall table width */
  }
  
  .material-react-table th:nth-child(1),
  .material-react-table td:nth-child(1) {
    width: 20%; /* Set the width for the first column */
  }
  
  .material-react-table th:nth-child(2),
  .material-react-table td:nth-child(2) {
    width: 40%; /* Set the width for the second column */
  }
  
  .material-react-table th:nth-child(3),
  .material-react-table td:nth-child(3) {
    width: 40%; /* Set the width for the third column */
  }
  